var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pageContol listWrap templateList formCom"},[_c('div',{staticClass:"framePage"},[_c('div',{staticClass:"framePage-halfBody"},[_c('div',{staticClass:"framePage-article"},[_c('div',{staticClass:"ovy-a"},[_c('h3',{staticStyle:{"padding-bottom":"10px"}},[_vm._v("课程目录")]),_c('section',{staticClass:"chapterl-trees createdCourse"},[_c('el-tree',{attrs:{"data":_vm.data,"node-key":"kpointVideoId","default-expand-all":""},on:{"node-click":_vm.getNodeD},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('div',{staticClass:"custom-tree-node"},[(data.level === 1)?_c('p',{staticClass:"flexac"},[_c('span',{staticStyle:{"width":"44px","text-align":"left"}},[_c('span',{staticClass:"level",staticStyle:{"margin-right":"10px"}},[_vm._v(" "+_vm._s(data.chapterNum)+" ")])]),_c('span',{staticClass:"ellipsis",staticStyle:{"margin-right":"20px"},attrs:{"title":data.catalogName}},[_vm._v(_vm._s(data.catalogName))])]):(data.level === 2)?[_c('draggable',{staticClass:"tree-drag-group",attrs:{"group":{
                      name: 'people',
                      put: true,
                      pull: false,
                    },"sort":false,"handle":".good","prevent-on-filter":true},on:{"change":function (evt) {
                        _vm.nodeChange(evt, data);
                      }}},[_c('span',{staticStyle:{"width":"60px","text-align":"left"}},[(_vm.styleType == '1')?_c('span',{staticClass:"level",staticStyle:{"margin-right":"10px"}},[_vm._v(" "+_vm._s(data.chapterNum)+" ")]):_c('span',{staticClass:"level",staticStyle:{"margin-right":"10px"}},[_vm._v(" "+_vm._s(data.chapterNum)+" ")])]),_c('span',{staticClass:"ellipsis",staticStyle:{"margin-right":"3px","width":"203px"},attrs:{"title":data.catalogName}},[_vm._v(_vm._s(data.catalogName))])])]:_vm._e()],2)}}])})],1)])]),_c('div',{staticClass:"framePage-body"},[_c('div',{staticClass:"framePage-scroll"},[_c('div',{staticClass:"ovy-a"},[(_vm.videoId)?_c('div',{staticStyle:{"height":"800px"},attrs:{"id":"disv"}},[_c('div',{staticClass:"prism-player",attrs:{"id":"J_prismPlayer"}})]):_c('div',{staticStyle:{"display":"flex","height":"100%","justify-content":"center","align-items":"center","flex-direction":"column"}},[_c('img',{attrs:{"src":require("../../../assets/videoEmpty.png")}}),_c('p',{staticStyle:{"font-size":"1.5rem","margin-top":"15px"}},[_vm._v("请选择播放的视频")])])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }