<template>
  <div class="pageContol listWrap templateList formCom">
    <div class="framePage">
      <!-- <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">{{ stu == 'examination' ? '数据统计' : '班级列表'}}</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">考试查询</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">答题详情</a>
        </span>
      </div> -->
      <div class="framePage-halfBody">
        <div class="framePage-article">
          <div class="ovy-a">
            <h3 style="padding-bottom:10px;">课程目录</h3>
            <section class="chapterl-trees createdCourse">
              <el-tree
                :data="data"
                node-key="kpointVideoId"
                @node-click="getNodeD"
                default-expand-all
              >
                <div class="custom-tree-node" slot-scope="{ node, data }">
                  <p class="flexac" v-if="data.level === 1">
                    <span style="width: 44px; text-align: left">
                      <span class="level" style="margin-right: 10px">
                        <!-- 一级 -->
                        {{ data.chapterNum }}
                      </span>
                    </span>

                    <span
                      :title="data.catalogName"
                      class="ellipsis"
                      style="margin-right: 20px"
                      >{{ data.catalogName }}</span
                    >
                  </p>
                  <template v-else-if="data.level === 2">
                    <draggable
                      class="tree-drag-group"
                      :group="{
                        name: 'people',
                        put: true,
                        pull: false,
                      }"
                      :sort="false"
                      @change="
                        (evt) => {
                          nodeChange(evt, data);
                        }
                      "
                      handle=".good"
                      :prevent-on-filter="true"
                    >
                      <span style="width: 60px; text-align: left">
                        <span
                          class="level"
                          v-if="styleType == '1'"
                          style="margin-right: 10px"
                        >
                          <!-- 二级 -->
                          {{ data.chapterNum }}
                        </span>
                        <span class="level" v-else style="margin-right: 10px">
                          <!-- 一级 -->
                          {{ data.chapterNum }}
                        </span>
                      </span>
                      <span
                        :title="data.catalogName"
                        class="ellipsis"
                        style="margin-right: 3px; width: 203px"
                        >{{ data.catalogName }}</span
                      >
                    </draggable>
                  </template>
                </div>
              </el-tree>
            </section>
          </div>
        </div>
        <div class="framePage-body">
          <div class="framePage-scroll">
            <div class="ovy-a">
              <div style="height:800px;" id="disv" v-if="videoId">
                <div class="prism-player" id="J_prismPlayer"></div>
              </div>
              <div v-else style="display:flex;height:100%;justify-content:center;align-items:center;flex-direction: column;">
                 <img src="../../../assets/videoEmpty.png" />
                 <p style="font-size:1.5rem;margin-top:15px">请选择播放的视频</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
// import player2 from "@/components/player2";
import { createSwigger, distorySwigger } from "../../../utils/swigger";

export default {
  name: "ExaminationDetails",
  components: {
    // Empty,
    // player2
  },
  mixins: [List],
  data() {
    return {
      data: [],
      videoId: "",
      player: null,
    };
  },
  created() {
     this.$nextTick(() => {
       this.getDatas();
    });
   
  },
  mounted() {},
  computed: {},
  methods: {
    getDatas() {
      const params = {
        json: this.$route.query.json,
      };
      this.$post("/run/course/displayCourse", params).then((res) => {
        ;
        if (res.data.isChapter) {
          this.styleType = 1;
          res.data.ecCourseNodes.map((el) => {
            el.level = 1;
            el.children.map((els) => {
              els.level = 2;
            });
            this.data = res.data.ecCourseNodes;
          });
        } else {
          this.styleType = 2;
          res.data.ecCourseNodes.map((el) => {
            console.log(el);
            let moreCourse = [];
            moreCourse.push({
              ind: new Date().getTime(),
              level: 2,
              catalogName: el.catalogName,
              isEdit: "0",
              section: el.section,
              kpointVideoId: el.kpointVideoId,
            });
            this.data = [...this.data, ...moreCourse];
          });
        }
        this.mapData(this.data);
      });
    },
    mapData(data) {
      //样式1下
      data.forEach((item, i) => {
        item["chapterNum"] = Number(i) + 1;
        if (item.children) {
          item.children.forEach((el, k) => {
            el["chapterNum"] = Number(i) + 1 + "-" + (Number(k) + 1);
          });
        }
      });

      this.$nextTick(() => {
        this.data = data;
      });
    },
    //获取树节点
    getNodeD(data, node) {
      console.log(data.kpointVideoId);
      this.videoId = data.kpointVideoId;
      if(this.videoId) {
         this.start();
      }
     
    },
    start() {
      let that = this;
      // that.dd()
      //查询节点信息
      that.$post("/sys/video/videoPlayAuthNoAuth", { videoId: that.videoId})
        .then((result1) => {
          if (result1.status === "0") {
            setTimeout(() => {
              if (that.player) {
                that.player.dispose();
                distorySwigger()
              }

              that.player = new Aliplayer(
                {
                  id: "J_prismPlayer",
                  width: "100%",
                  height: "100%",
                  autoplay: true,
                  format: "m3u8",
                  encryptType: "1",
                  isLive: false,
                  rePlay: false,
                  playsinline: true,
                  preload: true,
                  controlBarVisibility: "hover",
                  useH5Prism: true,
                  //支持播放地址播放,此播放优先级最高
                  //source: "http://cloud.video.taobao.com/play/u/2554695624/p/1/e/6/t/1/fv/102/28552077.mp4",
                  //播放方式二：点播用户推荐
                  vid: that.videoId,
                  playauth: result1.data,
                  //cover: 'http://liveroom-img.oss-cn-qingdao.aliyuncs.com/logo.png',
                  skinLayout: [
                    { name: "bigPlayButton", align: "blabs", x: 30, y: 80 },
                    { name: "H5Loading", align: "cc" },
                    { name: "errorDisplay", align: "tlabs", x: 0, y: 0 },
                    { name: "infoDisplay" },
                    { name: "tooltip", align: "blabs", x: 0, y: 56 },
                    { name: "thumbnail" },
                    {
                      name: "controlBar",
                      align: "blabs",
                      x: 0,
                      y: 0,
                      children: [
                        { name: "progress", align: "blabs", x: 0, y: 44 },
                        { name: "playButton", align: "tl", x: 15, y: 12 },
                        { name: "timeDisplay", align: "tl", x: 10, y: 7 },
                        // { name: "fullScreenButton", align: "tr", x: 10, y: 12 },
                        // { name: "setting", align: "tr", x: 15, y: 12 },
                        { name: "volume", align: "tr", x: 5, y: 10 },
                      ],
                    },
                  ],
                },
                function(player) {
                  that.previewEnd = false;
                  // this.html.querySelector(
                  //   ".preview-component-layer"
                  // ).style.display = "none";
                  player.on("ready", function() {
                    // let vid = document.querySelector("#J_prismPlayer video");
                    // vid.disablePictureInPicture = true;
                    //  vid.disablePictureInPicture = true;
                    createSwigger('','监管');
                  });
                }
              );
            }, 500);
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.formCom {
  h3 {
    padding: 15px 0;
  }
  .el-form-item {
    margin-bottom: 22px;
  }
  .chapterl-btns {
    .el-button {
      padding: 8px;
    }
  }
  .el-cascader {
    width: 100%;
  }
  .el-progress {
    width: 200px;
    padding-left: 15px;
  }
  .chapterl-trees {
    padding: 15px 10px 15px 0;
    .el-tree-node__content {
      height: 36px;
    }
    .draggable-item {
      margin-left: 10px;
      em {
        margin-right: 5px;
      }
    }

    .trees-btns {
      display: flex;
      align-items: center;
      .showbtn {
        color: #fff;
      }
      margin-left: 15px;
      & > em,
      .handle > em {
        color: #4089fa;
        font-size: 14px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .chapteru-lists {
    padding: 15px 15px 15px 0;
    .el-divider--horizontal {
      margin: 5px;
    }
    .cl-subhint {
      display: flex;
      span {
        font-size: 14px;
        color: #666;
        &:nth-child(2) {
          margin-left: 15px;
        }
      }
      em {
        margin: 0 5px;
      }
    }
    .cl-btns {
      display: flex;
      align-items: center;
      width: 4rem;
      justify-content: space-between;
      i {
        color: #999999;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .el-icon-receiving {
    width: 42px;
    height: 42px;
    font-size: 40px;
    color: #999;
    margin-right: 15px;
  }
  .pageForm-btns {
    padding: 15px 0;
    text-align: center;
    .el-button {
      width: 10rem;
      span {
        min-width: 4em;
      }
    }
  }
  .file-list-group {
    .list-group-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }
  .tree-drag-group {
    display: flex;
    align-items: center;
    font-size: 14px;
    .list-group-item {
      margin-left: 5px;
      display: inline-block;
      .el-icon-receiving {
        font-size: 22px;
        color: #2d2;
      }
      .df {
        display: none;
      }
    }
  }
  .bgc-height {
    background-color: #f4ff00;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: 148px !important;
    height: 148px !important;
  }
  .hide /deep/.el-upload--picture-card {
    display: none;
  }
}
</style>
<style lang="less" scope>
.framePage-halfBody {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.framePage-halfBody .framePage-article {
  background-color: #fff;
  margin-right: 0.675rem;
  height: 100%;
  padding: 1rem 0.675rem;
  box-sizing: border-box;
  width: 25%;
  min-width: 20rem;
}
.formBox-seat {
  width: 70%;
  margin: 0 auto;
  padding: 1rem;
  box-sizing: border-box;
}
.formBox-seat .itembox {
  display: flex;
}
.formBox-seat .itembox > label {
  width: 6rem;
}
.formBox-seat .el-form-item {
  margin-bottom: 15px;
}
.formBox-seat .itembox .el-form-item__content {
  flex: 1;
}
.formBox-seat .itembox .el-form-item__content .el-select {
  width: 100%;
}
.createdCourse {
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 240px;
    font-size: 14px;
  }
  .level {
    padding: 3px;
    border-radius: 6px;
    color: #333;
    background-color: #e0f2ff;
    min-width: 20px;
    text-align: center;
  }
}
</style>
